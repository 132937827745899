<template>
  <v-container class="pt-0">
    <v-row no-gutters>
      <v-col>
        <div
          v-if="quiz.header_title"
          class="intro_text_header"
          v-html="quiz.header_title"
        />
        <v-img
          v-if="quiz.header_image"
          :src="$getFullPath(quiz.header_image)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="8"
        offset-lg="2"
      >
        <img
          class="mt-8"
          src="@/assets/quiz/intro.png"
          width="100%"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="8"
        xs="6"
        offset-lg="2"
      >
        <h3
          v-if="error"
          class="text-center"
        >
          {{ error }}
        </h3>
        <div
          v-else
          class="text-intro"
          v-html="quiz.intro"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        md="auto"
        xs="auto"
      >
        <router-link
          v-if="!error"
          class="start_quiz_btn"
          :to="{ name: 'quiz', params: { id: quiz.id, question_id: 1 } }"
        >
          Start quiz
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="footer_logo">
        <img src="@/assets/quiz/logo.png">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Quizes',
  data () {
    return {
      error: false,
      quiz: {},
      results: []
    }
  },
  async beforeMount () {
    if (this.$route.query.email === undefined) {
      this.error = 'Sorry this page cannot be accessible without email'
    } else {
      await this.getResults()

      if (this.results.length > 0) {
        this.error = 'Sorry account with this email already completed this quiz'
      } else {
        this.getQuizInfo()
      }
    }
  },
  methods: {
    getResults () {
      return this.$http
        .get(`/results_p?email=${this.$route.query.email}&quiz_id${this.$route.params.id}`)
        .then((response) => {
          this.results = response.data.data
        })
        .catch(error => this.displayError(error))
    },
    async getQuizInfo () {
      // todo if answers is full answered display message then this was finished
      localStorage.setItem('email', this.$route.query.email)
      await this.$http
        .get(`/quizes/active/?id[]=${this.$route.params.id}&take=500&status=true`)
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (data.length === 0) {
            this.error = 'Sorry this quiz not found or disabled'
          }
          this.quiz = data[0]
        })

      const answers = JSON.parse(localStorage.getItem('answers' + this.$route.query.email + this.$route.params.id)) || []
      if (answers.length === this.quiz.questions.length) {
        this.error = 'You already finished this quiz'
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.intro_text_header {
  position: absolute;
  font-size: 100px;
  line-height: 120px;
  color: #fff;
  margin: 100px 0 0 30px;
  z-index: 999;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .intro_text_header {
    font-size: 70px;
    line-height: 90px;
    margin: 40px 0 0 30px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .intro_text_header {
    font-size: 40px;
    line-height: 40px;
    margin: 10px 0 0 30px;
  }
  .text-intro {
    font-size: 20px;
  }
}
</style>
